import axios from "axios"

export function getOrderReports(CustomerID, LocationID, From, To, cancelToken) {
  return axios.get(`/Customer/${CustomerID}/OrderReport`, {
    cancelToken,
    params: {
      LocationID,
      fromDate: From,
      toDate: To
    }
  })
}

export function getSupplierDetailReports(SupplierID, From, To, cancelToken) {
  return axios.post(
    `/Supplier/${SupplierID}/Details`,
    {
      From,
      To
    },
    {
      cancelToken
    }
  )
}


export function sendLogs(SupplierID, Date, cancelToken) {
    return axios.post(
        `/POS/Logs/Resend?SupplierID=${SupplierID}&Date=${Date}`,
    )
}

export function getPOSDetailReportResend(Id, cancelToken) {
    return axios.post(
        `/POS/Logs/${Id}/Resend`,
        {
            params: {},
            cancelToken
        }
    )
}

export function getPOSDetailReports(SupplierID, Date, Status, cancelToken) {
    return axios.get(
        `/POS/Logs`,
        {
            params: {
                SupplierID,
                Date,
                Status
            },
            cancelToken
        }
    )
}

export function getCustomerDetailReports(
  CustomerID,
  locationID,
  date,
  cancelToken
) {
  return axios.get(`/Customer/${CustomerID}/OrderReport/Detailed`, {
    params: { date, locationID },
    cancelToken
  })
}

export function getCustomerReports(
    CustomerID,
    location,
    from,
    to,
    cancelToken
) {
    return axios.get(`/Customer/${CustomerID}/Report/General`, {
        params: { from, to , location },
        cancelToken
    })
}

export function getCustomerBillingReports(
    CustomerID,
    location,
    from,
    to,
    cancelToken
) {
    return axios.get(`/Customer/${CustomerID}/Billing/Report/General`, {
        params: { from, to, location },
        cancelToken
    })
}

export function getCustomerBillingUsersReports(
    CustomerID,
    location,
    from,
    to,
    cancelToken
) {
    return axios.get(`/Customer/${CustomerID}/Billing/Report/Users`, {
        params: { from, to, location },
        cancelToken
    })
}

export function getCustomerBillingCreditReports(
    CustomerID,
    location,
    from,
    to,
    cancelToken
) {
    return axios.get(`/Customer/${CustomerID}/Billing/Report/Credit`, {
        params: { from, to, location },
        cancelToken
    })
}

export function getPushReports(
    CustomerID,
    departmentID,
    locationID,
    from,
    to,
    cancelToken
) {
    return axios.get(`/Customer/Report/Notifications`, {
        params: { from, to, locationID, customerID: CustomerID, departmentID },
        cancelToken
    })
}

export function getEmployeesOrdersCSV(
  customerID,
  departmentID,
  location,
  from,
  to,
  cancelToken
) {
  return axios.get(`/Reports/EmployeesOrdersCSV`, {
      params: { from, to , location, customerID , departmentID },
      cancelToken,
      responseType: 'blob'
  })
}

export function pushSync(
    cancelToken
) {
    return axios.post(`/history/push/sync`, {
        params: {},
        cancelToken
    })
}


export function getSupplierReports(
  SupplierID,
  LocationID,
  From,
  To,
  cancelToken
) {
  return axios.get(`/Supplier/${SupplierID}/OrderReport/v2`, {
    cancelToken,
    params: {
      LocationID,
      fromDate: From,
      toDate: To
    }
  })
}

export function getEmployeeReports(
  CustomerID,
  DepartmentID,
  LocationID,
  From,
  To,
  cancelToken
) {
  return axios.post(
    "/Reports/Employees/Orders",
    {
      CustomerID,
      DepartmentID,
      LocationID,
      From,
      To
    },
    { cancelToken }
  )
}

export function getEmployeeDetailedReports(
  CustomerID,
  DepartmentID,
  LocationID,
  From,
  To,
  cancelToken
) {
  return axios.post(
    "/Reports/EmployeesDetailedOrders",
    {
      CustomerID,
      DepartmentID,
      LocationID,
      From,
      To
    },
    { cancelToken }
  )
}

export function getRatingReports(SupplierId, CustomerID, From, To, cancelToken) {
  return axios.post(
    "/Reports/Items/Rating",
    {
      SupplierId,
      From,
      To,
      CustomerID
    },
    {
      cancelToken
    }
  )
}

export function getCreditCardReports(
  customerID,
  locationID,
  from,
  to,
  firstName,
  lastname,
  type,
  cancelToken,
  filterByOrderToDate
) {
  return axios.post(
    `/Reports/Order/Payment`,
    {
      CustomerID: customerID,
      LocationID: locationID,
      StartDate: from,
      EndDate: to,
      FirstName: firstName,
      LastName: lastname,
      Type: type,
      FilterByOrderToDate: filterByOrderToDate
    },
    {
      cancelToken
    }
  )
}

export function getCancelledItemOrderReports(
    SupplierID,
    CustomerID,
    From,
    To,
    LocationID,
    cancelToken
) {
    return axios.post(
        "/Reports/Items/Orders/By/Type",
        {
            SupplierID,
            LocationID,
            CustomerID,
            From,
            To,
            Status: 2
        },
        {
            cancelToken
        }
    )
}

export function getItemOrderReports(
  SupplierID,
  LocationID,
  From,
  To,
  cancelToken
) {
  return axios.post(
    "/Reports/Items/Orders",
    {
      SupplierID,
      LocationID,
      From,
      To
    },
    {
      cancelToken
    }
  )
}

export function getCustomerStoreReports(
  CustomerID,
  LocationID,
  From,
  To,
  cancelToken
) {
  return axios.get(`Customer/${CustomerID}/OrderReport/Store`, {
    cancelToken,
    params: {
      LocationID,
      fromDate: From,
      toDate: To
    }
  })
}

export function getSupplierStoreReports(SupplierID, From, To, cancelToken) {
  return axios.get(`Supplier/${SupplierID}/OrderReport/Store`, {
    cancelToken,
    params: {
      fromDate: From,
      toDate: To
    }
  })
}

export function getRefundReports(params, cancelToken) {
    return axios.post("/Reports/Orders/Refund/Details", params, {
        cancelToken
    })
}

export function getWalletBalanceReports(
  CustomerID,
  DepartmentID,
  cancelToken
) {
  return axios.get(
    "/Wallet/BalanceReport",
    { 
      cancelToken,
      params: {
        costumerId: CustomerID,
        departmentId: DepartmentID
      } 
    }
  )
}

export function bankTransfer(data) {
    return axios.post(
        `/Bank/Transfers`,
        data
    )
}

export function getBankBalanceReports(
    CustomerID,
    DepartmentID,
    status,
    from,
    to,
    expectedTransferFrom,
    expectedTransferTo,
    cancelToken
) {
    return axios.get(
        `/Bank/Transfers`,
        {
            cancelToken,
            params: {
                CustomerID,
                DepartmentID,
                from,
                to,
                status,
                expectedTransferFrom,
                expectedTransferTo
            }
        }
    )
}

export function getBudgetReports(
  CustomerID,
  DepartmentID,
  From,
  To,
  cancelToken
) {
  return axios.get(
    "/Budget/Report",
    { 
      cancelToken,
      params: {
        customerID: CustomerID,
        departmentID: DepartmentID,
        fromDate: From,
        toDate: To
      } 
    }
  )
}
