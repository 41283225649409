/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Chip,
  FilledInput,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import MealItemList from "./MealItemList"
import MealComboList from "./MealComboList"
import MealSelectorDialog from "../../../Management/Meals/MealSelectorDialog"
import AddMealComboFormDialog from "./AddMealComboFormDialog"
import SetDateRangeDialog from "./SetDateRangeDialog"
import AddIcon from "@material-ui/icons/Add"
import { TimePicker } from "@material-ui/pickers"
import moment from "moment"
import { dayCollection } from "../../../Common/dayCollection"
import ExportButton from "../../../Reports/ExportButton"
import { formatDate } from "../../../Common/momentFunctions"
import DeleteButton from "./DeleteButton"
import ConfirmationDialog from "../../../Common/ConfirmationDialog"
import {Tab, Tabs} from "react-bootstrap"
import DeleteIcon from "@material-ui/icons/Delete";

function MealInfoForm({
  classes,
  intl,
  exportFileName,
  tags,
  fieldNames,
  locationID,
  formik,
  suppliers,
  takenItemsIDs,
  takenCombosIDs,
  mealTypeIndex,
  saveMealItems,
  saveMealCombos,
  checkForError,
  renderErrors,
  mealsSearchText,
  onMealsSearchTextChange,
  displayedItems,
  displayedCombos
}) {
  const [exportData, setExportData] = useState([])

  const [showAddMealItemFormDialog, setShowAddMealItemFormDialog] = useState(
    false
  )
  const [showAddMealComboFormDialog, setShowAddMealComboFormDialog] = useState(
      false
  )
  const [dateRangeDialogState, setDateRangeDialogState] = useState({
    show: false
  })

  const [selectedMealItemIDs, setSelectedMealItemIDs] = useState([])
  const [selectedMealComboIDs, setSelectedMealComboIDs] = useState([])

  const [deleteDialogData, setDeleteDialogData] = useState({ show: false })
  const [deleteComboDialogData, setDeleteComboDialogData] = useState({ show: false })

  const {
    days,
    deliveryMinTime,
    deliveryMaxTime,

    userCloseCancelTime,
    userCloseOrderTime,
    userCloseEditTime,
    managerCloseCancelTime,
    managerCloseOrderTime,
    managerCloseEditTime,

    menuFavouriteTags,
    mealTimes,
    mealDeliveryTimes,

    minOrderQuantity,

    mealItems,
    mealCombos,
    mealID
  } = fieldNames

  function handleSelectMealItem(mealItemID) {
    if (selectedMealItemIDs.includes(mealItemID)) {
      setSelectedMealItemIDs(selectedMealItemIDs.filter(id => id !== mealItemID))
    } else {
      setSelectedMealItemIDs([...selectedMealItemIDs, mealItemID])
    }
  }

  function handleSelectMealCombo(mealComboID) {
    if (selectedMealComboIDs.includes(mealComboID)) {
      setSelectedMealComboIDs(selectedMealComboIDs.filter(id => id !== mealComboID))
    } else {
      setSelectedMealComboIDs([...selectedMealComboIDs, mealComboID])
    }
  }

  function handleDeleteSelectedMealCombos() {
    const allMealCombos = formik.values[mealCombos].filter(
        mealCombo => !selectedMealComboIDs.includes(mealCombo.Combo.Id)
    )
    saveMealCombos(formik.values[mealID], mealCombos, allMealCombos, () => {})
    setSelectedMealComboIDs([])
    setDeleteComboDialogData({ show: false })
  }

  function handleDeleteSelectedMealItems() {
    const allMealItems = formik.values[mealItems].filter(
      mealItem => !selectedMealItemIDs.includes(mealItem.Item.ItemID)
    )
    saveMealItems(formik.values[mealID], mealItems, allMealItems, () => {})
    setSelectedMealItemIDs([])
    setDeleteDialogData({ show: false })
  }

  function handleAddMealItems(newMealItems) {
    const allMealItems = [...formik.values[mealItems], ...newMealItems]
    const closeDialog = () => setShowAddMealItemFormDialog(false)
    saveMealItems(formik.values[mealID], mealItems, allMealItems, closeDialog)
  }

  function handleAddMealCombos(newMealCombos) {
    const allMealCombos = [...formik.values[mealCombos], ...newMealCombos.map((item) => {
      return {
        Combo: item,
        ClientPrice: item.ClientPrice,
        CustomerPrice: item.CustomerPrice,
        Priority: 0,
        Days: [...item.Days],
        From: null,
        To: null
      }
    })]
    const closeDialog = () => setShowAddMealComboFormDialog(false)
    saveMealCombos(formik.values[mealID], mealCombos, allMealCombos, closeDialog)
  }

  function handleComboDaysChange(event, mealComboID, dayValue) {
    const changedMealCombos = [...formik.values[mealCombos]]
    const changedMealCombo = changedMealCombos.find(
        mealCombo => mealCombo.Combo.Id === mealComboID
    )

    if (event.target.checked) {
      changedMealCombo.Days = [...(changedMealCombo.Days || []), dayValue]
    } else {
      changedMealCombo.Days = changedMealCombo.Days?.filter(
          value => value !== dayValue
      ) || []
    }
    formik.setFieldValue(mealCombos, [...changedMealCombos])
  }

  function handleDaysChange(event, mealItemID, dayValue) {
    const changedMealItems = [...formik.values[mealItems]]
    const changedMealItem = changedMealItems.find(
      mealItem => mealItem.Item.ItemID === mealItemID
    )

    if (event.target.checked) {
      changedMealItem.Days = [...changedMealItem.Days, dayValue]
    } else {
      changedMealItem.Days = changedMealItem.Days.filter(
        value => value !== dayValue
      )
    }
    changedMealItem.changed=  true;
    formik.setFieldValue(mealItems, [...changedMealItems])
  }

  function handleComboFieldChange(event, fieldName, mealComboID) {
    const value = event.target.value
    const changedComboItems = [...formik.values[mealCombos]]
    const changedMealCombo = changedComboItems.find(
        mealCombo => mealCombo.Combo.Id === mealComboID
    )
    changedMealCombo[fieldName] = value
    changedMealCombo.changed = true;
    formik.setFieldValue(mealCombos, [...changedComboItems])
  }

  function handleItemFieldChange(event, fieldName, mealItemID) {
    const value = event.target.value
    const changedMealItems = [...formik.values[mealItems]]
    const changedMealItem = changedMealItems.find(
      mealItem => mealItem.Item.ItemID === mealItemID
    )

    changedMealItem[fieldName] = value;
    changedMealItem.changed = true;
    formik.setFieldValue(mealItems, [...changedMealItems])
  }

  function handleSetDateRange(mealItemID, availableFrom, availableTo) {
    const changedMealItems = [...formik.values[mealItems]]
    const changedMealItem = changedMealItems.find(
      mealItem => mealItem.Item.ItemID === mealItemID
    )
    changedMealItem.From = availableFrom
    changedMealItem.To = availableTo
    changedMealItem.changed = true;
    formik.setFieldValue(mealItems, [...changedMealItems])
    setDateRangeDialogState({ show: false })
  }

  function renderCloseTimeField(closeTimeFieldName) {
    return (
      <TextField
        className={classes.textField}
        margin="normal"
        variant="filled"
        type="number"
        hiddenLabel
        inputProps={{
          min: 0,
          className: classes.input
        }}
        fullWidth
        {...formik.getFieldProps(closeTimeFieldName)}
        error={checkForError(closeTimeFieldName)}
      />
    )
  }

  function renderUserCloseOrderTime() {
    switch (formik.values.orderType) {
      case 1: // same day
        return moment((formik.getFieldProps(`${mealDeliveryTimes}`).value || [])[0]?.From)
          .subtract(formik.values[userCloseOrderTime], "minute")
          .format("HH:mm")
      case 2: // next day
        return (
          " יום לפני " +
          moment()
            .startOf("day")
            .add(1, "day")
            .subtract(formik.values[userCloseOrderTime], "minute")
            .format("HH:mm")
        )
      case 3: // weekly
        return (
          " שישי לפני " +
          moment()
            .startOf("day")
            .add(1, "day")
            .subtract(formik.values[userCloseOrderTime], "minute")
            .format("HH:mm")
        )
      default:
        return null
    }
  }

  useEffect(() => {
    function getExportData() {
      return displayedItems.map(meal => {
        const formattedMeal = {}
        formattedMeal[
          intl.formatMessage({
            id: "EXPORT_FILE_NAME.MEALS.BREAKFAST"
          })
        ] = meal.Item.Name
        formattedMeal[
          intl.formatMessage({
            id: "ADMIN_USERS.FILTER.SUPPLIER_FIELD.LABEL"
          })
        ] = meal.Item.SupplierName
        formattedMeal[
          intl.formatMessage({
            id: "MEAL_ITEM.PRIORITY_FIELD.LABEL"
          })
        ] = meal.Priority
        formattedMeal[
          intl.formatMessage({
            id: "MEAL_ITEM.LABELS.DAYS"
          })
        ] = dayCollection.reduce(
          (str, day) =>
            meal.Days.includes(day.value)
              ? `${str && `${str}, `}${intl.formatMessage({
                  id: day.label.props.id
                })}`
              : str,
          ""
        )
        formattedMeal[
          intl.formatMessage({
            id: "MEAL_ITEM.LABELS.AVAILABILITY"
          })
        ] = meal.From ? `${formatDate(meal.From)}-${formatDate(meal.To)}` : ""
        return formattedMeal
      })
    }
    setExportData(getExportData())
  }, [displayedItems])

  function removeDeliveryTime(index) {
    const deliveryTypes = (formik.getFieldProps(`${mealDeliveryTimes}`).value || []).slice();
    deliveryTypes.splice(index, 1)
    formik.setFieldValue(`${mealDeliveryTimes}`, deliveryTypes)
  }

  function addDeliveryTime() {
    const deliveryTypes = (formik.getFieldProps(`${mealDeliveryTimes}`).value || []).slice();
    deliveryTypes.push({
      From: null,
      To: null
    });
    formik.setFieldValue(`${mealDeliveryTimes}`, deliveryTypes)
  }

  return (
    <>
      <div className={classes.mealContainer}>
        <Tabs
            defaultActiveKey="info"
            id="type-tab"
            unmountOnExit
        >
          <Tab
              eventKey="info"
              title={<FormattedMessage id="TABS.INFO"/>}
          >
            <>

              <div className={classes.root}>
                <div className="row">
                  <div className="col-1" />
                  <div className="col-11">
                    {dayCollection.map(day => (
                        <FormControlLabel
                            key={day.value}
                            style={{ margin: 0 }}
                            control={
                              <Checkbox
                                  checked={formik.values[days].includes(day.value)}
                                  style={{ padding: 0 }}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      formik.setFieldValue(days, [
                                        ...formik.values[days],
                                        day.value
                                      ])
                                    } else {
                                      formik.setFieldValue(
                                          days,
                                          formik.values[days].filter(
                                              value => value !== day.value
                                          )
                                      )
                                    }
                                  }}
                                  color="secondary"
                              />
                            }
                            label={day.label}
                            labelPlacement="end"
                        />
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.MIN_ORDER_QUANTITY_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-3">
                    <TextField
                        id="min-order-quantity"
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        type="number"
                        hiddenLabel
                        inputProps={{
                          min: 0,
                          className: classes.input
                        }}
                        fullWidth
                        {...formik.getFieldProps(minOrderQuantity)}
                        error={checkForError(minOrderQuantity)}
                    />
                    {renderErrors(minOrderQuantity)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1 pt-3 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.USER_CLOSE_CANCEL_TIME_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-0">
                    {renderCloseTimeField(userCloseCancelTime)}
                  </div>
                  <div className="col-1 pt-3 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.USER_CLOSE_ORDER_TIME_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-0">
                    {renderCloseTimeField(userCloseOrderTime)}
                  </div>
                  <div className="col-1 pt-3 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.USER_CLOSE_EDIT_TIME_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-0">
                    {renderCloseTimeField(userCloseEditTime)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-2 pl-3 pr-3 pt-0 pb-3 d-flex justify-content-center align-items-center text-right">
                    {moment((formik.getFieldProps(`${mealDeliveryTimes}`).value || [])[0]?.From)
                        .subtract(formik.values[userCloseCancelTime], "minute")
                        .format("HH:mm")}
                    {renderErrors(userCloseCancelTime)}
                  </div>
                  <div className="col-1"></div>
                  <div className="col-2 pl-3 pr-3 pt-0 pb-3 d-flex justify-content-center align-items-center text-right">
                    {renderUserCloseOrderTime()}
                    {renderErrors(userCloseOrderTime)}
                  </div>
                  <div className="col-1"></div>
                  <div className="col-2 pl-3 pr-3 pt-0 pb-3 d-flex justify-content-center align-items-center text-right">
                    {moment((formik.getFieldProps(`${mealDeliveryTimes}`).value || [])[0]?.From)
                        .subtract(formik.values[userCloseEditTime], "minute")
                        .format("HH:mm")}
                    {renderErrors(userCloseEditTime)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1 pt-3 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.MANAGER_CLOSE_CANCEL_TIME_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-0">
                    {renderCloseTimeField(managerCloseCancelTime)}
                  </div>
                  <div className="col-1 pt-3 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.MANAGER_CLOSE_ORDER_TIME_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-0">
                    {renderCloseTimeField(managerCloseOrderTime)}
                  </div>
                  <div className="col-1 pt-3 d-flex justify-content-end align-items-center pr-0 text-right">
                    <FormattedMessage id="CREATE_FORM.MANAGER_CLOSE_EDIT_TIME_FIELD.LABEL" />
                  </div>
                  <div className="col-2 pl-3 pt-3 pb-0">
                    {renderCloseTimeField(managerCloseEditTime)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-2 pl-3 pr-3 pt-0 pb-3 d-flex justify-content-center align-items-center text-right">
                    {moment((formik.getFieldProps(`${mealDeliveryTimes}`).value || [])[0]?.From)
                        .subtract(formik.values[managerCloseCancelTime], "minute")
                        .format("HH:mm")}
                    {renderErrors(managerCloseCancelTime)}
                  </div>
                  <div className="col-1"></div>
                  <div className="col-2 pl-3 pr-3 pt-0 pb-3 d-flex justify-content-center align-items-center text-right">
                    {moment((formik.getFieldProps(`${mealDeliveryTimes}`).value || [])[0]?.From)
                        .subtract(formik.values[managerCloseOrderTime], "minute")
                        .format("HH:mm")}
                    {renderErrors(managerCloseOrderTime)}
                  </div>
                  <div className="col-1"></div>
                  <div className="col-2 pl-3 pr-3 pt-0 pb-3 d-flex justify-content-center align-items-center text-right">
                    {moment((formik.getFieldProps(`${mealDeliveryTimes}`).value || [])[0]?.From)
                        .subtract(formik.values[managerCloseEditTime], "minute")
                        .format("HH:mm")}
                    {renderErrors(managerCloseEditTime)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 d-flex flex-column justify-content-center">
                    <div className="row">
                      <div className="col-2 d-flex justify-content-end align-items-center pr-0 text-right">
                        <FormattedMessage id="CREATE_MEAL_INFO_FORM.MENU_FAVOURITE_TAGS_FIELD.LABEL" />
                      </div>
                      <div className="col-10 pl-3 pt-3 pb-3">
                        <FormControl
                            variant="filled"
                            className={classes.textField}
                            fullWidth
                            error={checkForError(menuFavouriteTags)}
                        >
                          <Select
                              {...formik.getFieldProps(menuFavouriteTags)}
                              multiple
                              input={
                                <FilledInput
                                    name="menuFavouriteTags"
                                    inputProps={{
                                      className: classes.input
                                    }}
                                />
                              }
                              renderValue={selected => (
                                  <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip
                                            key={value}
                                            className={classes.chip}
                                            label={tags.find(tag => tag.TagID === value).Name}
                                        />
                                    ))}
                                  </div>
                              )}
                          >
                            {tags.map(tag => (
                                <MenuItem key={tag.Name} value={tag.TagID}>
                                  {tag.Name}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {renderErrors(menuFavouriteTags)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 d-flex justify-content-end align-items-center pr-0 text-right">
                        <FormattedMessage id="CREATE_MEAL_INFO_FORM.SUPPLIER_TIME.LABEL" />
                      </div>
                      <div className="col-10 pl-3 pt-3 pb-3">
                        <div className={classes.supplierItemContainer}>
                          {
                            formik.getFieldProps(mealTimes).value?.map((supplier, index) => {
                              return <div className={classes.supplierItem}>
                                <div>
                                  {
                                    supplier.Name
                                  }
                                </div>
                                <div className={classes.timeContainer}>
                                  <div className={classes.timeItem}>
                                    <FormattedMessage id="CREATE_MEAL_INFO_FORM.SUPPLIER_LIMIT_TIME.LABEL" />
                                    <TimePicker
                                        autoOk
                                        ampm={false}
                                        value={formik.getFieldProps(`${mealTimes}[${index}]`).value?.Time ? new Date(formik.getFieldProps(`${mealTimes}[${index}]`).value?.Time) : null}
                                        className={classes.textField}
                                        onChange={value => {
                                          formik.setFieldValue(`${mealTimes}[${index}].Time`, value)
                                        }}
                                        clearable
                                        TextFieldComponent={({
                                                               helperText,
                                                               InputProps,
                                                               ...props
                                                             }) => (
                                            <TextField
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                inputProps={{
                                                  className: classes.containerInput
                                                }}
                                                hiddenLabel
                                                {...props}
                                            />
                                        )}
                                    />
                                  </div>
                                  <div className={classes.timeItem}>
                                    <FormattedMessage id="CREATE_MEAL_INFO_FORM.SUPPLIER_PRINT_TIME.LABEL" />
                                    <TimePicker
                                        autoOk
                                        ampm={false}
                                        value={formik.getFieldProps(`${mealTimes}[${index}]`).value?.PrintTime ? new Date(formik.getFieldProps(`${mealTimes}[${index}]`).value?.PrintTime) : null}
                                        className={classes.textField}
                                        onChange={value => {
                                          formik.setFieldValue(`${mealTimes}[${index}].PrintTime`, value)
                                        }}
                                        clearable
                                        TextFieldComponent={({
                                                               helperText,
                                                               InputProps,
                                                               ...props
                                                             }) => (
                                            <TextField
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                inputProps={{
                                                  className: classes.containerInput
                                                }}
                                                hiddenLabel
                                                {...props}
                                            />
                                        )}
                                    />
                                  </div>
                                </div>
                              </div>
                            })
                          }
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-2 d-flex justify-content-end align-items-center pr-0 text-right">
                        <FormattedMessage id="CREATE_MEAL_INFO_FORM.DELIVERY_TIME.LABEL" />
                      </div>
                      <div className="col-10 pl-3 pt-3 pb-3">
                        <div className={classes.supplierItemContainer}>
                          {
                            formik.getFieldProps(mealDeliveryTimes).value?.map((supplier, index) => {
                              return <div className={classes.supplierItem}>
                                <div className={classes.timeContainer}>
                                  <div className={classes.timeItem}>
                                    <FormattedMessage id="CREATE_MEAL_INFO_FORM.DELIVERY_TIME_FROM.LABEL" />
                                    <TimePicker
                                        autoOk
                                        ampm={false}
                                        value={formik.getFieldProps(`${mealDeliveryTimes}[${index}]`).value?.From ? new Date(formik.getFieldProps(`${mealDeliveryTimes}[${index}]`).value?.From) : null}
                                        className={classes.textField}
                                        onChange={value => {
                                          formik.setFieldValue(`${mealDeliveryTimes}[${index}].From`, value)
                                        }}
                                        clearable
                                        TextFieldComponent={({
                                                               helperText,
                                                               InputProps,
                                                               ...props
                                                             }) => (
                                            <TextField
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                inputProps={{
                                                  className: classes.containerInput
                                                }}
                                                hiddenLabel
                                                {...props}
                                            />
                                        )}
                                    />
                                  </div>
                                  <div className={classes.timeItemDelimiter}>
                                    -
                                  </div>
                                  <div className={classes.timeItem}>
                                    <FormattedMessage id="CREATE_MEAL_INFO_FORM.DELIVERY_TIME_TO.LABEL" />
                                    <TimePicker
                                        autoOk
                                        ampm={false}
                                        value={formik.getFieldProps(`${mealDeliveryTimes}[${index}]`).value?.To ? new Date(formik.getFieldProps(`${mealDeliveryTimes}[${index}]`).value?.To) : null}
                                        className={classes.textField}
                                        onChange={value => {
                                          formik.setFieldValue(`${mealDeliveryTimes}[${index}].To`, value)
                                        }}
                                        clearable
                                        TextFieldComponent={({
                                                               helperText,
                                                               InputProps,
                                                               ...props
                                                             }) => (
                                            <TextField
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                InputLabelProps={{
                                                  shrink: true
                                                }}
                                                inputProps={{
                                                  className: classes.containerInput
                                                }}
                                                hiddenLabel
                                                {...props}
                                            />
                                        )}
                                    />
                                  </div>
                                  <div className={classes.timeItem}>
                                    {
                                      formik.getFieldProps(mealDeliveryTimes).value?.length > 1 && (
                                            <Button
                                                variant="contained"
                                                type="button"
                                                size="large"
                                                color="secondary"
                                                className={classes.button}
                                                disabled={formik.isSubmitting}
                                                onClick={() => removeDeliveryTime(index)}
                                            >
                                              <DeleteIcon/>
                                            </Button>
                                        )
                                    }
                                  </div>
                                </div>
                              </div>
                            })
                          }
                        </div>
                      </div>
                      <Button
                          variant="contained"
                          type="button"
                          size="large"
                          color="secondary"
                          className={classes.button}
                          disabled={formik.isSubmitting}
                          onClick={() => addDeliveryTime(true)}
                      >
                        <AddIcon className={classes.extendedIcon} />
                        <FormattedMessage id="CREATE_FORM.ADD_DELIVERY_TIME_BUTTON" />
                      </Button>
                    </div>



                  </div>
                </div>
              </div>

            </>
          </Tab>
          <Tab eventKey="item"
               title={<FormattedMessage id="TABS.MEAL_ITEM"/>}>
            <MealSelectorDialog
                show={showAddMealItemFormDialog}
                closeDialog={() => setShowAddMealItemFormDialog(false)}
                submitData={handleAddMealItems}
                suppliers={suppliers.filter(x => x.State !== "archive")}
                priceCategoryData={[]}
                takenItemsIDs={takenItemsIDs ? takenItemsIDs.map(id => ({ Item: { ItemID: id } })) : []}
                mealTypeIndex={mealTypeIndex}
            />
            <ConfirmationDialog
                show={deleteDialogData.show}
                onSubmit={handleDeleteSelectedMealItems}
                onClose={() => setDeleteDialogData({ show: false })}
                dialogTitle={<FormattedMessage id="DELETE_MEAL_ITEM_DIALOG.TITLE" />}
                contentText={
                    intl.formatMessage({
                      id: "DELETE_MEAL_ITEM_DIALOG.TEXT"
                    }) +
                    `(${selectedMealItemIDs.length})?`
                }
            />
            <SetDateRangeDialog
                show={dateRangeDialogState.show}
                saveRange={(from, to) =>
                    handleSetDateRange(dateRangeDialogState.mealItemID, from, to)
                }
                closeDialog={() => setDateRangeDialogState({ show: false })}
                maxDate={dateRangeDialogState.maxDate}
                minDate={dateRangeDialogState.minDate}
                initialStartDate={dateRangeDialogState.from}
                initialEndDate={dateRangeDialogState.to}
            />
            <div className={classes.root}>
              <div className="position-relative">
                <TextField
                    label={intl.formatMessage({
                      id: "SEARCH_FIELD.LABEL"
                    })}
                    value={mealsSearchText}
                    onChange={event => onMealsSearchTextChange(event.target.value)}
                />
                <Button
                    variant="contained"
                    type="button"
                    size="large"
                    color="secondary"
                    className={classes.button}
                    disabled={formik.isSubmitting}
                    onClick={() => setShowAddMealItemFormDialog(true)}
                >
                  <AddIcon className={classes.extendedIcon} />
                  <FormattedMessage id="CREATE_FORM.NEW_MEAL_ITEM_BUTTON" />
                </Button>
                {renderErrors(mealItems)}
                {displayedItems.length !== 0 && (
                    <div className="d-inline">
                      <ExportButton exportData={exportData} fileName={exportFileName} />
                    </div>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <MealItemList
                      locationID={locationID}
                      mealID={formik.values[mealID]}
                      mealItems={displayedItems}
                      selectedIDs={selectedMealItemIDs}
                      onSelect={handleSelectMealItem}
                      onDaysChange={handleDaysChange}
                      onItemFieldChange={handleItemFieldChange}
                      setDateRangeDialogState={setDateRangeDialogState}
                  />
                </div>
              </div>
            </div>
              {selectedMealItemIDs.length > 0 &&
                  <DeleteButton onClick={() => setDeleteDialogData({ show: true })} />
              }
          </Tab>
          {process.env.REACT_APP_FLAVOR!='NY' ? <Tab
              eventKey="combo"
              title={<FormattedMessage id="TABS.MEAL_COMBO"/>}
          >
            <>
              <ConfirmationDialog
                  show={deleteComboDialogData.show}
                  onSubmit={handleDeleteSelectedMealCombos}
                  onClose={() => setDeleteComboDialogData({ show: false })}
                  dialogTitle={<FormattedMessage id="DELETE_MEAL_COMBO_DIALOG.TITLE" />}
                  contentText={
                      intl.formatMessage({
                        id: "DELETE_MEAL_COMBO_DIALOG.TEXT"
                      }) +
                      `(${selectedMealComboIDs.length})?`
                  }
              />
              <AddMealComboFormDialog
                  show={showAddMealComboFormDialog}
                  closeDialog={() => setShowAddMealComboFormDialog(false)}
                  submitData={handleAddMealCombos}
                  suppliers={suppliers}
                  takenCombosIDs={takenCombosIDs}
                  mealTypeIndex={mealTypeIndex}/>
                <Button
                    variant="contained"
                    type="button"
                    size="large"
                    color="secondary"
                    className={classes.button}
                    disabled={formik.isSubmitting}
                    onClick={() => setShowAddMealComboFormDialog(true)}
                >
                  <AddIcon className={classes.extendedIcon} />
                  <FormattedMessage id="CREATE_FORM.NEW_MEAL_COMBO_BUTTON" />
                </Button>
              <MealComboList
                  locationID={locationID}
                  mealID={formik.values[mealID]}
                  mealCombos={displayedCombos}
                  selectedIDs={selectedMealComboIDs}
                  onSelect={handleSelectMealCombo}
                  onDaysChange={handleComboDaysChange}
                  onComboFieldChange={handleComboFieldChange}
                  setDateRangeDialogState={setDateRangeDialogState}/>
            </>
            {selectedMealComboIDs.length > 0 &&
                <DeleteButton onClick={() => setDeleteComboDialogData({ show: true })} />
            }
          </Tab> : null}
        </Tabs>
      </div>
    </>
  )
}

export default injectIntl(MealInfoForm)
