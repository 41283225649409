/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { getMealTypeText } from "../../../modules/Common/mealTypesTranslation"

const headRows = [
  {
    id: "ItemName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "SupplierName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.SUPPLIER_NAME" />
  },
  {
    id: "CustomerName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.CUSTOMER_NAME" />
  },
  {
    id: "EmployeeName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.EMPLOYEE_NAME" />
  },
  {
    id: "Mobile",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.MOBILE" />
  },
  {
    id: "Email",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.EMAIL" />
  },
  {
    id: "LocationName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.LOCATION_NAME" />
  },
  {
    id: "MealType",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.MEAL_TYPE" />
  },
  {
    id: "DeliveryTime",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DELIVERY_TIME" />
  },
  {
    id: "UserInfoName",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.STATUS_UPDATE_USER_INFO" />
  },
]

function ItemCancellationList({ reportsData, intl }) {
  const classes = useStyles()
  const rows = reportsData.reports
  const [sortBy, setSortBy] = useState("CustomerName")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={rows.ItemID}>
                  <TableCell component="th" scope="row" align="left">
                    {row.ItemName}
                  </TableCell>
                  <TableCell>{row.SupplierName}</TableCell>
                  <TableCell>{row.CustomerName}</TableCell>
                  <TableCell>{row.EmployeeName}</TableCell>
                  <TableCell>{row.Mobile}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.LocationName}</TableCell>
                  <TableCell>{getMealTypeText(intl, row.MealType)}</TableCell>
                  <TableCell>{row.DeliveryTime?.From}-{row.DeliveryTime?.To}</TableCell>
                  <TableCell>
                    <div>
                      {row.UserInfo?.FirstName} {row.UserInfo?.LastName}
                    </div>
                    <div>
                      {row.UserInfo?.Email}
                    </div>
                    <div>
                      {row.UserInfo?.Mobile}
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default ItemCancellationList
