/* eslint-disable no-restricted-imports */

import { Button, Typography } from "@material-ui/core"
import axios from "axios"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { FormattedMessage, injectIntl } from "react-intl"
import { useParams, withRouter } from "react-router-dom"
import CustomerDetails from "../modules/Customers/CustomerDetails.js"
import {
  getCustomersList,
  updateCustomer,
  deleteCustomer,
  saveCustomerLogo,
  createCustomer,
  getCustomer
} from "../modules/Customers/_axios/customerCrud.js"
import LocationPanel from "../modules/Customers/Locations/LocationPanel.js"
import SpecialDatesPanel from "../modules/Customers/SpecialDates/SpecialDatesPanel.js"
import DepartmentPanel from "../modules/Customers/Departments/DepartmentPanel.js"
import UserPanel from "../modules/Customers/Users/UserPanel.js"
import CustomerDownshift from "../modules/Customers/CustomerDownshift.js"
import CreateCustomerFormDialog from "../modules/Common/CreateWithNameFormDialog.js"
import ConfirmationDialog from "../modules/Common/ConfirmationDialog.js"
import DishLoader from "../modules/Common/DishLoader.js"
import OrdersPanel from "../modules/Customers/Orders/OrdersPanel.js"
import { actions as snackbarActions } from "../../redux/snackbar/snackbarRedux"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers.js"
import { useStyles } from "../modules/Common/_styles/editPageStyles"

function CustomersEditPage({ intl, ...props }) {
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.auth.user.Role)
  const userCustomerInfo = useSelector(state => state.auth.user.Customer)
  const classes = useStyles()
  let { id: customerID } = useParams()
  const [customersData, setCustomersData] = useState({
    customers: [],
    isLoading: true
  })
  const [selectedCustomer, setSelectedCustomer] = useState({
    data: {},
    isLoading: false
  })

  const [showCreateSupplierPanel, setShowCreateCustomerPanel] = useState(false)

  const [deleteDialogData, setDeleteDialogData] = useState({
    show: false,
    isSubmitting: false
  })

  const isUserAdmin = userRole === "Admin" || userRole === "Owner"

  function handleSelectCustomer(data) {
    if (data.CustomerID) {
      setSelectedCustomer({ data, isLoading: true })
    } else {
      setSelectedCustomer({ data: {}, isLoading: false })
    }
  }

  function handleFetchCustomerData(cancelToken, customerID) {
    getCustomer(cancelToken.token, customerID)
      .then(({ data }) => {
        if (!customersData.customers.length) {
          setCustomersData({
            ...customersData,
            customers: [data],
            isLoading: false
          })
        }
        setSelectedCustomer({ data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_CUSTOMER"
          })
        )
      )
  }

  function fetchCustomers(cancelToken) {
    setCustomersData({ ...customersData, isLoading: true })

    if (isUserAdmin) {
      // Request for Admin
      getCustomersList(cancelToken.token)
        .then(({ data }) => {
          setCustomersData({
            ...customersData,
            customers: data,
            isLoading: false
          })
          if (customerID) {
            handleSelectCustomer(
              data.find(customer => customer.CustomerID === customerID) || {}
            )
          }
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
            })
          )
        )
    } else {
      // Request for Customer Admin
      handleFetchCustomerData(cancelToken, userCustomerInfo.CustomerID)
    }
  }

  function saveCustomer(submittedCustomer) {
    return updateCustomer(submittedCustomer, submittedCustomer.CustomerID)
  }

  function saveImage(customerID, imageFile) {
    const data = new FormData()
    data.append("file", imageFile)
    return saveCustomerLogo(data, customerID)
  }

  function updateCustomers(submittedCustomer) {
    const customers = [...customersData.customers]
    const changedCustomer = customers.find(
      customer => customer.CustomerID === submittedCustomer.CustomerID
    )
    if (changedCustomer !== undefined) {
      changedCustomer.Name = submittedCustomer.Name
      changedCustomer.CompanyID = submittedCustomer.CompanyID
      changedCustomer.Address = submittedCustomer.Address
      changedCustomer.GeoPoint = submittedCustomer.GeoPoint
      changedCustomer.ContactName = submittedCustomer.ContactName
      changedCustomer.ContactPhone = submittedCustomer.ContactPhone
      changedCustomer.ContactEmail = submittedCustomer.ContactEmail
      changedCustomer.AdvanceOrderType = submittedCustomer.AdvanceOrderType

      if (submittedCustomer.Logo) {
        changedCustomer.Logo = submittedCustomer.Logo
      }
      setSelectedCustomer({ data: { ...changedCustomer } })
      setCustomersData({
        ...customersData,
        customers: [...customers],
        isLoading: false
      })
    } else {
      const newCustomer = {
        Address: "",
        CompanyID: "",
        GeoPoint: null,
        ContactName: "",
        ContactPhone: "",
        ContactEmail: "",
        AdvanceOrderType: 0,
        Logo: [],
        Departments: [],
        Locations: [],
        Name: submittedCustomer.Name,
        CustomerID: submittedCustomer.CustomerID
      }
      setCustomersData({
        ...customersData,
        customers: [...customers, newCustomer]
      })
      setShowCreateCustomerPanel(false)
      setSelectedCustomer({ data: { ...newCustomer } })
    }
  }

  function removeCustomer(customerID) {
    setSelectedCustomer({ data: {} })
    setCustomersData({
      ...customersData,
      customers: [
        ...customersData.customers.filter(
          customer => customer.CustomerID !== customerID
        )
      ]
    })
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchCustomers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (selectedCustomer.data.CustomerID) {
      props.history.push(`/customer/${selectedCustomer.data.CustomerID}`)
      const cancelToken = axios.CancelToken.source()
      handleFetchCustomerData(cancelToken, selectedCustomer.data.CustomerID)
      return () => cancelToken.cancel()
    }
  }, [selectedCustomer.data.CustomerID])

  const handleNewCustomerSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const newCustomer = { Name: values.name }
    createCustomer(newCustomer)
      .then(({ data }) => {
        setSubmitting(false)
        dispatch(
          snackbarActions.setSnackbarData(
            intl.formatMessage({
              id: "API.CREATE_SUCCESS"
            })
          )
        )
        updateCustomers({
          ...newCustomer,
          CustomerID: data.CustomerID
        })
        resetForm()
      })
      .catch(error => {
        setSubmitting(false)
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_CREATE_CUSTOMER"
          })
        )
      })
  }

  const handleAcceptDeleteCustomer = () => {
    setDeleteDialogData({ ...deleteDialogData, isSubmitting: true })

    deleteCustomer(selectedCustomer.data.CustomerID)
      .then(() => {
        removeCustomer(selectedCustomer.data.CustomerID)
        setDeleteDialogData({
          show: false,
          isSubmitting: false
        })
      })
      .catch(error => {
        setDeleteDialogData({
          ...deleteDialogData,
          isSubmitting: false
        })
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_DELETE_CUSTOMER"
          })
        )
      })
  }

  return (
    <>
      <ConfirmationDialog
        show={deleteDialogData.show}
        onSubmit={handleAcceptDeleteCustomer}
        onClose={() =>
          setDeleteDialogData({
            show: false,
            isSubmitting: false
          })
        }
        isSubmitting={deleteDialogData.isSubmitting}
        dialogTitle={<FormattedMessage id="DELETE_CUSTOMER_DIALOG.TITLE" />}
        contentText={
          intl.formatMessage({
            id: "DELETE_CUSTOMER_DIALOG.TEXT"
          }) +
          " " +
          selectedCustomer.data.Name +
          "?"
        }
      />
      <div className={classes.headerPinned}>
        <Typography variant="h6" className={classes.title}>
          <FormattedMessage id="TITLE" />
        </Typography>
        {isUserAdmin && (
          <>
            <CustomerDownshift
              selectedCustomer={selectedCustomer.data}
              selectCustomer={handleSelectCustomer}
              customersData={customersData}
            />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className={classes.button}
              onClick={() => setShowCreateCustomerPanel(true)}
            >
              <FormattedMessage id="CREATE_NEW_BUTTON" />
            </Button>
            <CreateCustomerFormDialog
              show={showCreateSupplierPanel}
              title={<FormattedMessage id="CREATE_FORM.TITLE" />}
              closeDialog={() => setShowCreateCustomerPanel(false)}
              handleSubmit={handleNewCustomerSubmit}
              checkIfNameUnique={value => {
                const customer = customersData.customers.find(
                  customer => customer.Name === value
                )
                return customer === undefined
              }}
            />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className={classes.button}
              component={Link}
              to="/customers"
            >
              <FormattedMessage id="GO_BACK" />
            </Button>
          </>
        )}
      </div>
      {selectedCustomer.data.CustomerID ? (
        !selectedCustomer.isLoading ? (
          <div className="row h-100">
            <div className="col-md-12 p-0">
              <div className="card card-custom position-absolute h-100 w-100">
                <div className="card-body pt-0 overflow-auto">
                  <Tabs
                    id="main-tab"
                    className={classes.tabHeader}
                    unmountOnExit
                    defaultActiveKey="locations"
                  >
                    {isUserAdmin && (
                      <Tab
                        eventKey="details"
                        title={<FormattedMessage id="TABS.DETAILS" />}
                      >
                        <CustomerDetails
                          selectedCustomer={selectedCustomer.data}
                          customersData={customersData}
                          sumbitCustomer={saveCustomer}
                          deleteCustomer={() =>
                            setDeleteDialogData({
                              show: true,
                              isSubmitting: false
                            })
                          }
                          saveImage={saveImage}
                          updateCustomers={updateCustomers}
                          isUserAdmin={isUserAdmin}
                        />
                      </Tab>
                    )}
                    {isUserAdmin && (
                      <Tab
                        eventKey="locations"
                        title={<FormattedMessage id="TABS.LOCATIONS" />}
                      >
                        <LocationPanel
                          selectedCustomer={selectedCustomer.data}
                          updateCustomer={customer =>
                            setSelectedCustomer({ data: customer })
                          }
                        />
                      </Tab>
                    )}
                    {isUserAdmin && (
                      <Tab
                        eventKey="departments"
                        title={<FormattedMessage id="TABS.DEPARTMENTS" />}
                      >
                        <DepartmentPanel
                          departments={selectedCustomer.data.Departments}
                          selectedCustomer={selectedCustomer.data}
                          updateCustomer={customer =>
                            setSelectedCustomer({ data: customer })
                          }
                        />
                      </Tab>
                    )}
                    <Tab
                      eventKey="users"
                      title={<FormattedMessage id="TABS.USERS" />}
                    >
                      <UserPanel
                        userRole={userRole}
                        selectedCustomer={selectedCustomer.data}
                      />
                    </Tab>
                    <Tab
                      eventKey="orders"
                      title={<FormattedMessage id="TABS.ORDERS" />}
                    >
                      <OrdersPanel
                        selectedCustomer={selectedCustomer.data}
                        isUserAdmin={isUserAdmin}
                      />
                    </Tab>
                    {isUserAdmin && (
                        <Tab
                            eventKey="specialDates"
                            title={<FormattedMessage id="TABS.SPECIAL_DATES" />}
                        >
                          <SpecialDatesPanel
                              selectedCustomer={selectedCustomer.data}
                              updateCustomer={customer =>
                                  setSelectedCustomer({ data: customer })
                              }
                          />
                        </Tab>
                    )}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DishLoader centered />
        )
      ) : (
        ""
      )}
    </>
  )
}

export default withRouter(injectIntl(CustomersEditPage))
