/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import { FormControlLabel } from "@material-ui/core"
import { useStyles } from "../Common/_styles/formDialogStyles"
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"

function Thumb({ iconFile, initialURL }) {
  const [state, setState] = useState({
    loading: false,
    thumb: initialURL
  })

  useEffect(() => {
    if (iconFile) {
      setState({ loading: true })
      let reader = new FileReader()

      reader.onloadend = () => {
        setState({ loading: false, thumb: reader.result })
      }

      reader.readAsDataURL(iconFile)
    }
  }, [iconFile])

  if (!iconFile && !initialURL) {
    return null
  }

  if (state.loading) {
    return <span className="spinner spinner-white"></span>
  }

  return (
    <img
      src={state.thumb}
      alt={iconFile ? iconFile.name : "tag icon"}
      className="img-thumbnail mt-2"
      height={200}
      width={200}
    />
  )
}

function CreateTagFormDialog({
  show,
  initialTagState,
  closeDialog,
  submitData,
  submitIcon,
  tagsData,
  intl
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  let initialValues
  if (initialTagState.TagID) {
    initialValues = {
      name: initialTagState.Name,
      iconFile: null,
      parent: initialTagState.ParentTag ? initialTagState.ParentTag.TagID : "",
      priority: initialTagState.Priority,
      menu: initialTagState.Menu,
      store: initialTagState.Store
    }
  } else {
    initialValues = {
      name: "",
      iconFile: null,
      parent: "",
      priority: 0,
      menu: false,
      store: false
    }
  }

  function handleSumbit(data) {
    closeDialog(data)
  }

  function handleClose() {
    closeDialog(null)
  }

  const nameValidationSchema = Yup.string()
    .test(
      "nameIsUnique",
      intl.formatMessage({
        id: "VALIDATION.NAME_USED"
      }),
      value => {
        const tag = tagsData.tags.find(tag => tag.Name === value)
        return tag === undefined || tag.TagID === initialTagState.TagID
      }
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const priorityValidationSchema = Yup.number()
    .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const iconFileValidationSchema = Yup.mixed()
    .when({
      is: () => {
        return !initialTagState.TagID
      },
      then: Yup.mixed().required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      )
    })
    .test(
      "fileType",
      intl.formatMessage({
        id: "VALIDATION.UNSUPPORTED_FILE_FORMAT"
      }),
      value => {
        if (value) {
          return value.type === "image/svg+xml"
        }
        return true
      }
    )

  const validationSchema = Yup.object().shape(
    {
      name: nameValidationSchema,
      priority: priorityValidationSchema,
      iconFile: iconFileValidationSchema
    },
    ["name", "priority", "iconFile"]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const { name, iconFile, parent, priority, menu, store } = values
      const tokenData = {}
      tokenData.Name = name
      tokenData.ParentTagID = parent
      tokenData.Priority = priority
      tokenData.Menu = menu
      tokenData.Store = store
      submitData({
        tagId: initialTagState.TagID,
        tokenData
      })
        .then(({ data }) => {
          if (initialTagState.TagID && !iconFile) {
            dispatch(
              snackbarActions.setSnackbarData(
                intl.formatMessage({
                  id: "API.EDIT_SUCCESS"
                })
              )
            )
            handleSumbit({ TagID: initialTagState.TagID, ...tokenData })
          } else {
            submitIcon(data ? data.TagID : initialTagState.TagID, iconFile)
              .then(() => {
                setSubmitting(false)
                dispatch(
                  snackbarActions.setSnackbarData(
                    intl.formatMessage({
                      id: data ? "API.CREATE_SUCCESS" : "API.EDIT_SUCCESS"
                    })
                  )
                )
                handleSumbit(
                  data || { TagID: initialTagState.TagID, ...tokenData }
                )
                resetForm()
              })
              .catch(error => {
                setSubmitting(false)
                handleApiError(
                  dispatch,
                  error,
                  intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_SAVE_IMAGE"
                  })
                )
              })
          }
        })
        .catch(error => {
          setSubmitting(false)
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: initialTagState.TagID
                ? "API.ERROR.FAILED_TO_UPDATE_TAG"
                : "API.ERROR.FAILED_TO_CREATE_TAG"
            })
          )
        })
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span className={classes.error}>{formik.errors[fieldName]}</span>
    ) : null
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="CREATE_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent className={classes.smallForm}>
            <div className="position-relative">
              <TextField
                id="name"
                name="name"
                label={intl.formatMessage({
                  id: "CREATE_FORM.NAME_FIELD.LABEL"
                })}
                className={classes.textField}
                margin="normal"
                variant="filled"
                fullWidth
                {...formik.getFieldProps("name")}
                error={checkForError("name")}
              />
              {renderErrors("name")}
            </div>
            <div className="d-flex flex-row position-relative pb-2">
              <div className="col-10 text-center p-0">
                <Thumb
                  iconFile={formik.values.iconFile}
                  initialURL={initialTagState.IconURL}
                />
              </div>
              <div className="col-2 text-right align-self-end p-0">
                <Button
                  variant="contained"
                  component="label"
                  className={classes.chooseIconButton}
                >
                  ...
                  <input
                    id="icon-file"
                    name="iconFile"
                    type="file"
                    accept="image/svg+xml"
                    onChange={event => {
                      formik.setFieldValue(
                        "iconFile",
                        event.currentTarget.files[0]
                      )
                    }}
                    hidden
                  />
                </Button>
              </div>
              {renderErrors("iconFile")}
            </div>
            <div className="position-relative">
              <FormControl
                variant="filled"
                className={classes.textField}
                fullWidth
                error={checkForError("parent")}
              >
                <InputLabel htmlFor="parent-select">
                  <FormattedMessage id="CREATE_FORM.PARENT_FIELD.LABEL" />
                </InputLabel>
                <Select
                  {...formik.getFieldProps("parent")}
                  input={<FilledInput name="parent" id="parent-select" />}
                >
                  {tagsData.tags.map(tag => (
                    <MenuItem key={tag.TagID} value={tag.TagID}>
                      {tag.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {renderErrors("parent")}
            </div>
            <div className="position-relative">
              <TextField
                id="priority"
                name="priority"
                label={intl.formatMessage({
                  id: "CREATE_FORM.PRIORITY_FIELD.LABEL"
                })}
                className={classes.textField}
                margin="normal"
                variant="filled"
                type="number"
                inputProps={{
                  min: 0
                }}
                fullWidth
                {...formik.getFieldProps("priority")}
                error={checkForError("priority")}
              />
              {renderErrors("priority")}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.menu}
                  name="menu"
                  color="secondary"
                  {...formik.getFieldProps("menu")}
                />
              }
              label={intl.formatMessage({
                id: "CREATE_FORM.MENU_FIELD.LABEL"
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.store}
                  name="store"
                  color="secondary"
                  {...formik.getFieldProps("store")}
                />
              }
              label={intl.formatMessage({
                id: "CREATE_FORM.STORE_FIELD.LABEL"
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="CREATE_FORM.SUBMIT_BUTTON" />
              {formik.isSubmitting && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(CreateTagFormDialog)
