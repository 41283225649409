/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import moment from 'moment';

const headRows = [
    {
        id: "OrderToDate",
        disablePadding: false,
        notSortable: true,
        width: 21.5,
        label: ""
    },
    {
        id: "MealType",
        disablePadding: false,
        notSortable: true,
        width: 12,
        label: ""
    },
    {
        id: "ItemsCount",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.ITEMS" />
    },
    {
        id: "RefundCount",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
    },
    {
        id: "TotalCount",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.TOTAL" />
    },
    {
        id: "SubsidyAmount",
        disablePadding: false,
        notSortable: true,
        width: 13,
        label: ""
    },
    {
        id: "Total",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.TOTAL_COST" />
    }
]

function OrderItemsSummary({ reportsData }) {
  const classes = useStyles()
  const rows = reportsData
    const [sortBy, setSortBy] = useState("Total")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  const totalItemsCount = reportsData.reduce((sum, report) => sum + report.ItemsCount, 0)
  const totalRefundCount = reportsData.reduce((sum, report) => sum + report.RefundCount, 0)
  const totalTotalCount = totalItemsCount - totalRefundCount
  const totalTotal = reportsData.reduce((sum, report) => sum + ((report.ItemsCount - report.RefundCount) * report.SubsidyAmount), 0)

  const date = reportsData.length > 0 ? moment.utc(reportsData[0].OrderToDate).format('YYYY-MM') : ""

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapperSummary}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.collapsibleRow}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{totalItemsCount}</TableCell>
              <TableCell>{totalRefundCount}</TableCell>
              <TableCell>
                <span className='value-direction'>
                  {totalTotalCount}
                </span>
              </TableCell>

              <TableCell></TableCell>
              <TableCell>{totalTotal.toFixed(2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsSummary
