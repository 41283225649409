/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { getSorting, stableSort } from "../../Common/tableSortingFunctions"
import moment from 'moment';

const headRows = [
  {
    id: "OrderToDate",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.DATE" />
  },
  {
    id: "MealType",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.MEAL_TYPE" />
  },
  {
    id: "ItemsCount",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEMS" />
  },
  {
    id: "RefundCount",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
  },
  {
    id: "TotalCount",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL" />
  },
  {
    id: "SubsidyAmount",
    disablePadding: false,
    label: <FormattedMessage id="TABLE.HEAD_LABELS.SUBSIDY" />
  },
  {
    id: "Total",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_COST" />
  }
]

function CollapsibleRow({ row, indexId, classes }) {
    const [open, setOpen] = useState(false)

    const dateObj = row.OrderToDate != null ? moment.utc(row.OrderToDate) : null;
    const date = dateObj ? dateObj.format('DD-MM-YYYY') : "";

    const dayOfWeek = dateObj ? dateObj.day() : null;
    const dayOfWeekId = dayOfWeek !== null ? `DAYS.${['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][dayOfWeek]}` : "";

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.collapsibleRow}
      >
        <TableCell>{date} {dayOfWeekId && <FormattedMessage id={dayOfWeekId} />} </TableCell>
              <TableCell>{(row.MealType === 1
                  ? <FormattedMessage id="REPORT.BREAKFAST"/>
                  : row.MealType === 2
                    ? <FormattedMessage id="REPORT.LUNCH" />
                    : row.MealType === 3
                      ? <FormattedMessage id="REPORT.DINNER" />
                      : "")}</TableCell>
        <TableCell>{row.ItemsCount}</TableCell>
        <TableCell>{row.RefundCount}</TableCell>
        <TableCell>{row.ItemsCount - row.RefundCount}</TableCell>
        <TableCell>{row.SubsidyAmount.toFixed(2)}</TableCell>
        <TableCell><span className={classes.priceDefinition}>{(row.SubsidyAmount * (row.ItemsCount - row.RefundCount)).toFixed(2)}</span></TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function OrderItemsList({ reportsData }) {
  const classes = useStyles()
  const rows = reportsData
    const [sortBy, setSortBy] = useState("OrderToDate")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, i) => {
              return (
                <CollapsibleRow key={row.OrderToDate + "_" + row.MealType + "_" + i} row={row} classes={classes} />
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsList
