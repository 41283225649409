import axios from "axios"

export const SEND_OTP = "/Login/SendOTP"
export const RESEND_OTP = "/Login/ResendOTP"
export const VALIDATE_OTP = "/Login/ValidateOTP"
export const GET_USER_INFO = "/Login/GetUserInfo"
export const LOGIN_URL = "api/auth/login"
export const REGISTER_URL = "api/auth/register"
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password"

export const ME_URL = "api/me"

export function sendOtp(data) {
  return axios.post(SEND_OTP, data)
}

export function resendOtp(data) {
  return axios.post(RESEND_OTP, data)
}

export function validateOtp(data) {
  return axios.post(VALIDATE_OTP, data)
}

export function getUserInfo(token) {
  return axios.get(GET_USER_INFO, {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password })
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password })
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL)
}
