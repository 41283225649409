/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
  {
    id: "Name",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.ITEM_NAME" />
  },
  {
    id: "CatalogNumber",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.CATALOG_NUMBER" />
  },
  {
    id: "OrderQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.QUANTITY" />
  },
  {
    id: "RefundQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.REFOUND_QUANTITY" />
  },
  {
    id: "TotalQuantity",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.NET_QUANTITY" />
  },
  {
    id: "TotalOptionCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_OPTION_COST" />
  },
  {
    id: "TotalItemCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_ITEM_COST" />
  },
  {
    id: "TotalSupplierRefund",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_REFUND" />
  },
  {
    id: "TotalCost",
    disablePadding: false,
    label: <FormattedMessage id="REPORT.TOTAL_COST" />
  }
]


function OrderItemsList({ mealsData, searchParams, intl }) {
  const classes = useStyles()
  const rows = mealsData.Items
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")
  const summaryRows = [
    {label: '', id: 1},
    {label: '', id: 2},
    {label: mealsData.LocationQuantity, id: 3},
    {label: mealsData.LocationRefundQuantity, id: 4},
    {label: mealsData.LocationNetQuantity, id: 5},
    {label: mealsData.LocationTotalOptionCost, id: 6},
    {label: mealsData.LocationTotalItemCost, id: 7},
    {label: mealsData.LocationTotalRefund, id: 8},
    {label: mealsData.LocationTotalCost, id: 9},
  ];
  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            summaryRows={summaryRows}
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
              return (
                  <>
                    <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={mealsData.LocationName + "_" + index}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.Name}
                      </TableCell>
                      <TableCell>{row.CatalogNumber}</TableCell>
                      <TableCell>{row.OrderQuantity}</TableCell>
                      <TableCell>{row.RefundQuantity}</TableCell>
                      <TableCell>{row.TotalQuantity}</TableCell>
                      <TableCell>{row.TotalOptionCost}</TableCell>
                      <TableCell>{row.TotalItemCost}</TableCell>
                      <TableCell>{row.TotalSupplierRefund}</TableCell>
                      <TableCell>{row.TotalCost}</TableCell>
                    </TableRow>
                    <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={mealsData.LocationName + "_" + index}
                    >
                      <TableCell><FormattedMessage id="REPORT.OPTIONS" /></TableCell>
                      <TableCell colSpan={8}>
                        {
                          row.SelectedOptions?.map(item => (
                              <div key={item?.option?.id}>
                                <span className="mr-4"><b>{item?.option?.Name}</b></span>
                                <span>{item?.option?.Price}</span>
                              </div>
                          ))
                        }
                      </TableCell>
                    </TableRow>
                  </>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default injectIntl(OrderItemsList)
